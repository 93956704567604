import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getName, getPhoto, getEmail, getFirstName, getSessionId, getTableNumber, updateEmail, updateFirstName, updateMarketingOptIn, getCreateResult } from "../redux/slices";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { useSearchParams } from "react-router-dom";
import { Loading } from './Loading';

import './playerEntryDetails.css';
import { CaptureEmailDto } from "../types/player";
import ReactGA from "react-ga4";

type FormValues = {
    email: string;
    firstName: string;
    lastName: string;
    marketingOptIn: boolean;
    ageCheck: boolean;
};

export const NameAndEmail: React.FC = () => {
    const { register, handleSubmit, setValue, formState: { isValid } } = useForm<FormValues>();
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const name = useSelector(getName);
    const photo = useSelector(getPhoto);
    const email = useSelector(getEmail);
    const firstName = useSelector(getFirstName);
    const dispatch = useAppDispatch();
    const nav = useNavigate();

    // check if the player was created successfully, before giving the option to add an email address
    const createResult = useSelector(getCreateResult);

    // if the redux store is missing a session ID (or the session ID does not match the URL parameter), 
    // then redirect to the start page. Suspect that this is a shared link and this "new" user needs to start at the beginning of the journey
    let sessionId = searchParams.get('s');
    let tableNumber = searchParams.get('t');
    let venueCode = searchParams.get('v');
    const sessionIdRedux = useSelector(getSessionId);
    const tableNumberRedux = useSelector(getTableNumber);

    useEffect(() => {
        setValue("email", email, { shouldValidate: true });
        setValue("firstName", firstName, { shouldValidate: true });
    }, [email, firstName, setValue]);

    useEffect(() => {
        if (sessionIdRedux === null || tableNumberRedux === null) {
            nav(`/?s=${sessionId}&t=${tableNumber}&v=${venueCode}`);
        }

        if (sessionId !== sessionIdRedux || tableNumber !== tableNumberRedux) {
            nav(`/?s=${sessionId}&t=${tableNumber}&v=${venueCode}`);
        }
        else {
            setLoading(false);
        }
    }, [sessionId, tableNumber, venueCode, sessionIdRedux, tableNumberRedux, nav]);

    // END of "copied link" checking

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        dispatch(updateEmail(data.email));
        dispatch(updateFirstName(data.firstName));
        dispatch(updateMarketingOptIn(true));

        // send email address to the server
        // wrap name a photo in the player DTO and send it to the server
        const playerDTO: CaptureEmailDto = {
            Email: data.email,
            FirstName: data.firstName,
            LastName: data.lastName,
            MarketingOptIn: true,
            PlayerId: createResult.id
        }

        setLoading(true);

        // POST the created player DTO to the CreatePlayer endpoint
        try {
            const response: Response = await fetch(`/api/CaptureEmail/${sessionId}?t=${tableNumber}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(playerDTO)
            })

            // at the email stage - do we care about the response, and if so, what do we do with it?
            if (response.ok) {
                ReactGA.event("EmailCaptured", {venue_code:venueCode});
                nav(`/complete?s=${sessionId}&t=${tableNumber}&v=${venueCode}`);
            }
            setLoading(false);
            nav(`/complete?s=${sessionId}&t=${tableNumber}&v=${venueCode}`);

        } catch (error) {
            setLoading(false);
            nav(`/complete?s=${sessionId}&t=${tableNumber}&v=${venueCode}`);
        }

        nav(`/complete?s=${sessionId}&t=${tableNumber}&v=${venueCode}`);
    }


    if (loading) return (<Loading />);

    // Player was created scuccessfully, and there is a table number
    if (true) {
        // exception for the USA
        if (window.location.host==="play.flightclubdartsusa.com"){
            return (
                <div className="pageContainer altPage">
                    <div className="topBlock">
                        <div className="playerWelcomeContainer">
                            <div className="playerEnteredSuccess">{name}</div>
                            <div className="playerPhotoContainer">
                                <img className="playerPhotoMask" src={photo} alt="" />
                                <div className="playerPhotoBorder"><img src="./img/photo_border.png" alt="" /></div>
                            </div>
                            <div className="playerEnteredText">ENTERS GAME</div>
                        </div>
                    </div>
                    <div className="storyContainerHeader">
                        SAVE YOUR MEMORIES
                    </div>
                    <ul>
                        <li>Keep your group pictures</li>
                        <li>Rewatch action replays</li>
                        <li>In your inbox tomorrow</li>
                    </ul>
                    <div className="storyContainer">
                        <img src={'./img/fc-action-replay.gif'} width="300px" height="180px" alt="Action Replays and Photos" />
                    </div>
                    <div className="formContainer">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="text" placeholder="First Name" autoComplete="given-name"
                                {...register("firstName", {
                                    required: true
                                }
                                )}
                            />
                            <input type="text" placeholder="Email" autoComplete="email"
                                {...register('email', {
                                    required: true,
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email',
                                    },
                                })}
                            />

                            <span className="checkbox"><input defaultChecked={false} id="age_check" type="checkbox" 
                            {...register("ageCheck", {
                                required: true

                            })} /><label className="checkboxLabel" htmlFor="age_check"></label>  I confirm I am 18 years of age or older</span><br/>

                            <footer className="btn-container">
                                <button type="submit" disabled={!isValid}>RECEIVE STORY</button>
                            </footer>
                        </form>

                    </div>
                </div>
            );
        } else {
            return (
                <div className="pageContainer altPage">
                    <div className="topBlock">
                        <div className="playerWelcomeContainer">
                            <div className="playerEnteredSuccess">{name}</div>
                            <div className="playerPhotoContainer">
                                <img className="playerPhotoMask" src={photo} alt="" />
                                <div className="playerPhotoBorder"><img src="./img/photo_border.png" alt="" /></div>
                            </div>
                            <div className="playerEnteredText">ENTERS GAME</div>
                        </div>
                    </div>
                    <div className="storyContainerHeader">
                        SAVE YOUR MEMORIES
                    </div>
                    <ul>
                        <li>Keep your group pictures</li>
                        <li>Rewatch action replays</li>
                        <li>In your inbox tomorrow</li>
                    </ul>
                    <div className="storyContainer">
                        <img src={'./img/fc-action-replay.gif'} width="300px" height="180px" alt="Action Replays and Photos" />
                    </div>
                    <div className="formContainer">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="text" placeholder="First Name" autoComplete="given-name"
                                {...register("firstName", {
                                    required: true
                                }
                                )}
                            />
                            <input type="text" placeholder="Email" autoComplete="email"
                                {...register('email', {
                                    required: true,
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: 'Please enter a valid email',
                                    },
                                })}
                            />
                            <footer className="btn-container">
                                <button type="submit" disabled={!isValid}>RECEIVE STORY</button>
                            </footer>
                        </form>

                    </div>
                </div>
            );
        }
    }
    else {
        switch (createResult.reason) {
            case "Something went wrong":
                // TODO: Retry button
                return (
                    <div className="pageContainer">
                        <div className="logoContainer">
                            <img src={'./img/fc-logo.svg'} width="90px" alt="Flight Club" />
                        </div>
                        <div className="errorContainer">
                            <div className="errorTitleText">Error</div>
                            <div className="errorText">Please use the touchscreen or talk to a host in venue</div>
                        </div>
                    </div>
                );
            case "Pending":
                // TODO: Option to re-check to see if the player has been created
                return (
                    <div className="pageContainer">
                        <div className="logoContainer">
                            <img src={'./img/fc-logo.svg'} width="90px" alt="Flight Club" />
                        </div>
                        <div className="errorContainer">
                            <div className="errorTitleText">Error</div>
                            <div className="errorText">Taking longer than expected</div>
                        </div>
                    </div>
                );
            case "TeamFull":
            case "OcheFull":
                return (
                    <div className="pageContainer">
                        <div className="logoContainer">
                            <img src={'./img/fc-logo.svg'} width="90px" alt="Flight Club" />
                        </div>
                        <div className="errorContainer">
                            <div className="errorTitleText">Error</div>
                            <div className="errorText">Oche is full, please talk to a host</div>
                        </div>
                    </div>
                );

            case "EventNotLive":
                // This is not expected (as it should be caught by the check session endpoint)
                // however, just for completeness as a customer could have a shared link
                return (
                    <div className="pageContainer">
                        <div className="logoContainer">
                            <img src={'./img/fc-logo.svg'} width="90px" alt="Flight Club" />
                        </div>
                        <div className="errorContainer">
                            <div className="errorTitleText">Error</div>
                            <div className="errorText">Your booking is over, please talk to a host if this is incorrect</div>
                        </div>
                    </div>
                );
            default:
                // TODO: Retry button, possibly add additional information to G4Analytics to help diagnose?
                // Catchall as venue service can return other errors
                console.log(createResult);
                return (
                    <div className="pageContainer">
                        <div className="logoContainer">
                            <img src={'./img/fc-logo.svg'} width="90px" alt="Flight Club" />
                        </div>
                        <div className="errorContainer">
                            <div className="errorTitleText">Error</div>
                            <div className="errorText">Please use the touchscreen or talk to a host in venue</div>
                        </div>
                    </div>
                );
        }
    }

}